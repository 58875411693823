* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
}
body {
  /* background: rgb(82, 36, 151); */
  height: 100vh;
  background-image: linear-gradient(
    rgba(32, 1, 66) 1%,
    rgba(121, 35, 214) 50%,
    rgba(32, 1, 66) 90%
  );
}
.app {
  color: rgb(241, 237, 243);
  text-align: center;
  /* change from wv to percentage to avoid horizontal bar to appear */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#link {
  z-index: 1;
  background-image: linear-gradient(
    90deg,
    rgba(32, 1, 66, 0.6),
    rgba(32, 1, 66, 0.6) 60%,
    rgba(92, 15, 95, 0.6)
  );
  position: fixed;
  top: 0px;
  width: 100%;
  display: flex;
  margin: 0px;
  padding: 0px;
  justify-content: flex-start;
  align-items: center;
}
#apps,
#bio {
  padding-right: 1em;
}
#stage {
  background: #000;
  background-image: linear-gradient(
      80deg,
      rgba(255, 0, 0, 0),
      rgb(32, 1, 66) 60%,
      rgb(92, 15, 95)
    ),
    url("./background/cinema.jpeg");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: left top;
  z-index: -1;
  top: 0px;
  position: fixed;
  height: 100vh;
  width: 100%;
}
#bio-stage {
  background: #000;
  background-image: linear-gradient(
      90deg,
      rgb(25, 3, 36) 15%,
      rgba(32, 1, 66, 0.37) 50%,
      rgb(41, 1, 43)
    ),
    url("./background/bio.jpeg");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center top;
  z-index: -1;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
}
#bio-cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5em;
}
#bio-txt {
  text-align: right;
  width: 60vw;
  font-size: 3em;
  font-family: Poiret One;
  color: rgba(211, 138, 245, 0.747);
  text-shadow: 2px 2px 2px #000000, 2px 2px 3px #000000;
  font-weight: 500;
  padding-bottom: 1em;
}
.title {
  text-align: left;
  color: goldenrod;
  font-family: Raleway Dots;
  text-shadow: 3px 3px 5px goldenrod;
  font-size: 5em;
  font-weight: 500;
}
#foota {
  color: antiquewhite;
  font-family: Raleway Dots;
  font-size: 2em;
  font-weight: 500;
  width: 100%;
  background-image: linear-gradient(
    90deg,
    rgb(25, 3, 36) 15%,
    rgba(32, 1, 66, 0.37) 50%,
    rgb(41, 1, 43)
  );
  position: fixed;
  bottom: 0px;
}
#projects-box {
  margin-top: 3em;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
#projects-cont {
  width: 70%;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
}
#projects-cont a {
  color: rgba(252, 210, 96, 0.87);
  text-shadow: 1px 1px 1px rgb(0, 0, 0);
}
#projects-cont :hover {
  background: rgb(69, 40, 85);
}
.projects-app-img {
  margin-bottom: 2vh;
  margin-right: 0.5vw;
  border: dotted 1px rgb(77, 58, 90);
}
.projects-app-img img {
  opacity: 0.8;
  padding: 0.1vw;
  width: 100%;
  object-position: center 0;
}
/* ----------------FX-------------------------------------------- */
#link a {
  color: rgb(96, 255, 122);
  text-transform: uppercase;
  text-shadow: 2px 3px 2px #ff0000;
  font-family: Monoton;
  font-size: 2em;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}
@keyframes glow {
  from {
    color: rgb(96, 255, 122);
  }
  to {
    text-shadow: 0 0 10px rgb(21, 255, 60), 0 0 20px rgb(21, 255, 60),
      0 0 30px rgb(21, 255, 60);
  }
}
#link :hover {
  color: #d170d1;
  text-shadow: 0 0 20px #fd0dad, 0 0 30px #ff0080;
  -webkit-animation: glow 0s ease-in-out infinite alternate;
  -moz-animation: glow 0s ease-in-out infinite alternate;
  animation: glow 0s ease-in-out infinite alternate;
}
/* note that when using margin effects right or left, when the images load initially they will load to the side causing alignments problems */
.vanished {
  opacity: 0;
  width: 50%;
  transition: opacity 1s, width 0.2s cubic-bezier(0, 0.5, 1, 0.1);
}

.in-view {
  opacity: 1;
  width: 100%;
}
/* ----------------FX--------------------------------------------- */
.align-left {
  font-size: 1.5em;
  line-height: 1;
  text-align: left;
  padding-right: 0.5em;
  padding-left: 0.2em;
}
.projects-app-description {
  line-height: 1;
  font-size: 1.5em;
  text-align: justify;
  padding-right: 0.5em;
  padding-left: 0.2em;
}
#username-input,
.pass-input {
  outline: none;
}
.app-card {
  display: flex;
  flex-direction: column;
  border: solid rgb(77, 7, 7) 1px;
  width: 20vw;
}
.upload--align {
  display: flex;
  justify-content: space-between;
}

.input {
  color: rgb(95, 63, 41);
  outline: none;
  width: 100%;
  resize: none;
  background: rgb(201, 206, 178);
}
#upload-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 4px;
  row-gap: 4px;
}
#post-cont {
  display: flex;
  margin-bottom: 1em;
}
#post-img {
  max-width: 600px;
}
#patchdel-cont {
  margin: 2px;
  margin-bottom: 1em;
  border: solid 2px rgba(200, 77, 238, 0.815);
  background: rgb(81, 45, 163);
}
#update-img {
  width: 20vw;
}
#modal {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  position: fixed;
  width: 100vw;
  height: 100%;
  background: rgb(154, 241, 142);
  opacity: 0.7;
}
.non-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.patch-del-btn,
#contact-btn {
  font-family: Poiret One;
  cursor: pointer;
  background: rgb(170, 104, 196);
  color: rgb(53, 8, 83);
  font-size: 1em;
  height: 2em;
  border-style: none;
  font-weight: 550;
}
.patch-del-btn:hover {
  background-color: #cae79c;
}
#contact-btn:hover {
  background-color: #d897f1;
}
#email {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
#email form {
  z-index: 10;
  font-family: Poiret One;
  width: 40%;
  font-size: 1.5em;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-top: 5em;
}
#email input {
  font-family: Poiret One;
  font-size: 1em;
  color: #e4bdf3;
  padding-left: 0.5em;
  height: 1.5em;
  background: rgb(139, 66, 182);
  border-style: none;
  border-left: solid 1px rgb(99, 83, 66);
  border-bottom: solid 1px rgb(99, 83, 66);
  outline: none;
}
#email textarea {
  border: solid 1px rgb(99, 83, 66);
  outline: none;
  resize: none;
  min-height: 10vh;
  font-family: Poiret One;
  font-size: 1em;
  color: #e4bdf3;
  padding-left: 0.5em;
  background: rgb(139, 66, 182);
}
#github {
  margin-top: 10vh;
  width: 80px;
  height: 80px;
  background-image: url("./background/gh7.png");
  background-size: cover;
}

.logout {
  left: 0px;
  position: fixed;
  z-index: 103;
  width: 100vw;
  height: 100vh;
}
.login {
  display: none;
}
.log-layer0 {
  top: 0px;
  left: 0px;
  position: fixed;
  width: 100vw;
  height: 20vh;
  background: rgb(128, 35, 108);
  z-index: 100;
}
.log-layer1 {
  left: 0px;
  transform: rotateZ(45deg);
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: green;
  z-index: 101;
}
.log-layer2 {
  left: 0px;
  transform: rotateZ(-45deg);
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgb(25, 82, 156);
  z-index: 102;
}
.log-layer3 {
  bottom: 0px;
  left: 0px;
  position: fixed;
  width: 100vw;
  height: 80vh;
  background: rgb(172, 154, 57);
  z-index: 50;
}

/*------------------ stars------------------------------------- */
.stars-container {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.star {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(188, 126, 212, 0.8);
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    box-shadow: 0 1px 8px 1px rgba(255, 255, 255, 0.4);
  }
  50% {
    box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 3px 1px rgba(255, 255, 255, 0.4);
  }
}

/*------------------ stars------------------------------------- */

@media (max-width: 850px) {
  #projects-cont {
    grid-template-columns: auto;
  }
  #projects-cont {
    width: 100%;
  }
  #upload-grid {
    display: flex;
    flex-direction: column;
  }
  .app-card {
    width: 100%;
  }
  #update-img {
    width: 100vw;
  }
  .patch-del-btn,
  #contact-btn {
    color: #abff92;
  }
  #email form {
    width: 80%;
  }
  #link {
    width: 100%;
    left: 0px;
    justify-content: center;
  }
  #link a {
    font-size: 1.5em;
  }
  #bio-stage {
    background-position: 0% 0%;
    z-index: -1;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
  }
  .title {
    font-size: 3em;
    line-height: 0.8;
    text-shadow: 2px 2px 2px goldenrod, 2px 2px 3px goldenrod;
  }
  #bio-cont {
    margin-top: 3em;
    flex-direction: column;
  }
  #bio-txt {
    text-align: left;
    padding-top: 1em;
    font-size: 2em;
    width: 100vw;
    color: #d170d1;
  }
  #foota {
    position: static;
  }
}
